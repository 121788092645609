import React, { useEffect, useState } from "react"
import Picture from "../components/picture"
import Vimeo from "../components/vimeo"
import LightboxAdd from "../components/lightbox/add"
import Tooltip from "../components/tooltip"

const Slideshow = ({ slides, activeIndex, setActiveIndex, hasLightboxAdd, projectId, showTooltip }) => {
  const [tooltipVisible, setTooltipVisible] = useState(true)

  const handleSlideshowClick = e => {

    const winW = window.innerWidth || document.documentElement.clientWidth;
    const shouldIgnore = e.target.getAttribute('data-ignore');

    if (!shouldIgnore) {
			if (slides[activeIndex].vimeoUrl) {
				if (e.clientX < winW * 0.2) {
					prevSlide()
				} else if (e.clientX > winW * 0.8) {
					nextSlide()
				}
			} else {
				if (e.clientX < winW / 2) {
					prevSlide()
				} else {
					nextSlide()
				}
			}

      
    }
  }

  const nextSlide = () => {
    setActiveIndex(prev => {
      return prev === slides.length - 1 ? 0 : prev + 1
    })
  }

  const prevSlide = () => {
    setActiveIndex(prev => {
      return prev === 0 ? slides.length - 1 : prev - 1
    })
  }

  const handleKeyPress = event => {
    const key = event.key || event.keyCode;
    if (key === 'ArrowRight') {
      nextSlide()
    } else if (key === 'ArrowLeft') {
      prevSlide()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
    return () => {
      document.removeEventListener('keyup', handleKeyPress, false)
    }
  }, [])

  return (
    <>
    <div className="pabs slideshow-container" onClick={handleSlideshowClick}>
      {slides[activeIndex+2] &&
        <Slide className="shadow" content={slides[activeIndex+2]} projectId={projectId} key={slides[activeIndex+2].id} hasLightboxAdd={hasLightboxAdd} />
      }
      {slides[activeIndex+1] &&
        <Slide className="shadow" content={slides[activeIndex+1]} projectId={projectId} key={slides[activeIndex+1].id} hasLightboxAdd={hasLightboxAdd} />
      }
      {slides[activeIndex] &&
        <Slide
          content={slides[activeIndex]}
          projectId={projectId} key={slides[activeIndex].id}
          hasLightboxAdd={hasLightboxAdd}
          currentSlide
          onMouseEnterTooltipContainer={() => setTooltipVisible(true)}
          onMouseLeaveTooltipContainer={() => setTooltipVisible(false)}
        />
      }
    </div>
    <div className="mobile-gallery">
      {slides.map((slide, i) => {
        return (
          <div className="w100 pad" key={`slide-${i}`}>
            {slide.vimeoUrl ? (
              <Vimeo {...slide} withControls />
            ) : (
              <Picture {...slide} resize={{ height: 320 }} />
            )}
          </div>
        )
      })}
    </div>
    {showTooltip && tooltipVisible && (
      <Tooltip colour={false ? '#FFFFFF' : '#000000'}>
				{(area) => {
					if ((area === 'center') && slides[activeIndex].vimeoUrl) {
						return `PLAY/PAUSE`
					} else {
						return `${activeIndex + 1}/${slides.length}`
					}
					
				}}
			</Tooltip>
    )}
    </>
  )
}

const Slide = ({ className, content, projectId, force, hasLightboxAdd, currentSlide, onMouseEnterTooltipContainer, onMouseLeaveTooltipContainer }) => content && (
  <div className={`${className || ''} slide`}>
    <div className="slide-inner">
      {content.vimeoUrl ? (
        <div className="slide-video">
          <Vimeo
            {...content}
            withControls
            withOverlay
            autoplay
            reveal={{ isVisible: currentSlide }}
            onMouseEnterTooltipContainer={onMouseEnterTooltipContainer}
            onMouseLeaveTooltipContainer={onMouseLeaveTooltipContainer}
          />
        </div>
      ) : (
        <>
          <Picture
            className="proheight"
            {...content}
            resize={{
              height: 1080,
              quality: 100
            }}
            hires
            force={force}
          />
          {hasLightboxAdd && <LightboxAdd className="outer" assetId={content.id} projectId={projectId} />}
        </>
      )}
    </div>
  </div>
)

export default Slideshow
