import React, { useState, useContext, useEffect } from "react";
import { LightboxContext } from "./store";

const LightboxAdd = ({ assetId, projectId, className }) => {

  const [lightbox, setLightbox] = useContext(LightboxContext);

  // eslint-disable-next-line
  const [added, setAdded] = useState(false);

  const add = () => {
    setLightbox(prev => [
      ...prev,
      {id: assetId, projectId: projectId}
    ])
  }

  const remove = () => {

    const filtered = lightbox.filter(item => item.id !== assetId);

    if (filtered) {
      setLightbox(filtered)
    }
  }

  useEffect(() => {

    const inLightbox = lightbox.find(item => item.id === assetId);
    setAdded(inLightbox ? true : false);

  }, [lightbox])

  return (
    <div className={`lightbox-add ${className ? className : ''}`}>
      {!added ? (
        <span className="lightbox-add__text" data-ignore onClick={add}>
          Add to Lightbox
        </span>
      ) : (
        <span className="lightbox-add__text" data-ignore onClick={remove}>
          Remove from Lightbox
        </span>
      )}
    </div>
  );
};

export default LightboxAdd;
