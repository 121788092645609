import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql, navigate } from "gatsby"
// ---------------------------------------------
import { CloseIcon } from "../components/svgs"
import Layout from "../components/layout"
import LightboxLink from "../components/lightbox/link"
import Picture from "../components/picture"
import Vimeo from "../components/vimeo"
import Slideshow from "../components/slideshow"
import Transition, { Link } from "../components/transition"
import SEO from "../components/seo"

const ProjectGalleryTitle = ({
  artist,
  handleLink,
  byline,
  client,
  title,
  projectTitle,
}) => {
  return (
    <div className="gallery-title">
      {artist ? (
        <Link to={`/${artist.slug}`} handleLink={handleLink}>
          {byline ? (
            <span dangerouslySetInnerHTML={{ __html: byline }} />
          ) : client ? (
            <>
              <span className="title uppercase">{artist.title} </span>
              <i>{`for `}</i>
              <span className="uppercase"> {client}</span>
            </>
          ) : (
            <>
              <span className="uppercase">{projectTitle || title}</span>
              <i>{` by `}</i>
              <span className="title uppercase">{artist.title}</span>
            </>
          )}
        </Link>
      ) : (
        <div>
          {byline ? (
            <span dangerouslySetInnerHTML={{ __html: byline }} />
          ) : (
            <span className="title uppercase">{projectTitle} </span>
          )}
        </div>
      )}
    </div>
  )
}

const ProjectGalleryDebug = props => {
  console.log(props)
  return (
    <Layout>
      <h1>Hello</h1>
    </Layout>
  )
}

function useSortSlides(gallery) {
  const slidesReducer = gallery.reduce((currentSlides, block) => {
    if (block.source) {
      return [...currentSlides, ...block.source]
    } else {
      return [...currentSlides, block]
    }
  }, [])
  const slides = useRef(slidesReducer)
  return slides.current
}

const ProjectGallery = ({ data, location, pageContext }) => {
  // Content
  const {
    id,
    byline,
    client,
    gallery,
    title,
    projectTitle,
  } = data.craft.project[0]
  const artist = pageContext.artist
  const slides = useSortSlides(gallery)

  // console.log(slides)

  // console.log(gallery, 'gallery')
  // console.log(slides.current, 'slides.current')

  // UX
  const [activeIndex, setActiveIndex] = useState(0)
  const [showThumbnails, setShowThumbnails] = useState(false)

  // UI
  const handleThumbClick = i => {
    setShowThumbnails(false)
    setActiveIndex(i)
  }

  const handleBackButton = () => {
    if (window.history.length > 2) {
      window.history.back()
    } else {
      if (artist) {
        navigate(`/${artist.slug}`)
      } else {
        window.history.back()
      }
    }
  }

  // Styles
  const inverted =
    slides[activeIndex] && slides[activeIndex].vimeoUrl ? true : false
  // console.log(slides[activeIndex])

  // Return
  return slides ? (
    <Layout className={inverted ? "inverted" : ""}>
      <SEO
        title={artist ? `${artist.title} for ${client}` : `${projectTitle}`}
      />
      <Transition
        hideFooter
        location={location}
        render={handleLink => (
          <>
            <LightboxLink className="hide-on-mobile" handleLink={handleLink} />
            <div className="project-container">
              <div className="project-background" />

              <ProjectGalleryTitle
                artist={artist}
                handleLink={handleLink}
                byline={byline}
                client={client}
                title={title}
                projectTitle={title}
              />

              <button onClick={handleBackButton} className="close-gallery">
                <CloseIcon colour={inverted ? "#FFFFFF" : "#000000"} />
              </button>

              {/* SLIDESHOW */}
              {slides.length > 0 && (
                <Slideshow
                  slides={slides}
                  projectId={id}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  showTooltip={!showThumbnails && slides.length > 1}
                  hasLightboxAdd
                />
              )}

              {/* THUMBNAILS */}
              {slides && showThumbnails && (
                <div className="gallery-thumbnails big-thumbs">
                  <div className="artist-thumbnails">
                    {slides.map((slide, i) => (
                      <div
                        className={`thumb active`}
                        onClick={e => handleThumbClick(i)}
                        key={`slide-thumb-${i}`}
                      >
                        {slide.vimeoUrl ? (
                          <Vimeo {...slide} className="proheight" />
                        ) : (
                          <Picture
                            className="proheight"
                            {...slide}
                            resize={{ height: 320 }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="gallery-footer">
                <div
                  className="thumbs-link"
                  onClick={() => setShowThumbnails(!showThumbnails)}
                >
                  {showThumbnails ? "Slideshow" : "Thumbnails"}
                </div>
                {artist && (
                  <div className="thumbs-link">
                    <Link to={`/${artist.slug}`} handleLink={handleLink}>
                      Artist's Portfolio
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      />
    </Layout>
  ) : (
    <Layout>404</Layout>
  )
}

ProjectGallery.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectGallery

export const pageQuery = graphql`
  query ProjectPageQuery($id: [Craft_QueryArgument!]) {
    craft {
      # artist content coming from pageContext
      project: entries(type: "Projects", id: $id) {
        ... on Craft_projects_projects_Entry {
          ...ProjectGallery
        }
      }
    }
  }
`

export const projectGalleryFragment = graphql`
  fragment ProjectGallery on Craft_projects_projects_Entry {
    ## START
    id
    authorId
    title
    projectTitle
    slug
    byline
    client
    projectTypes {
      ... on Craft_projectTypes_Category {
        title
        slug
      }
    }
    thumbnails {
      ... on Craft_thumbnails_images_BlockType {
        id
        typeHandle
        source {
          ... on Craft_minkAssets_Asset {
            ...Asset
          }
        }
      }
      ... on Craft_thumbnails_video_BlockType {
        id
        typeHandle
        vimeoUrl
        videoWidth
        videoHeight
        startTime
        endTime
      }
    }
    gallery {
      ... on Craft_gallery_images_BlockType {
        id
        typeHandle
        source {
          ... on Craft_minkAssets_Asset {
            ...Asset
          }
        }
      }
      ... on Craft_gallery_video_BlockType {
        id
        typeHandle
        vimeoUrl
        videoWidth
        videoHeight
      }
    }
    ## END
  }
`
