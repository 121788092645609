import React, { useState, useEffect } from "react";

const Tooltip = ({ children, colour }) => {

  const [position, setPosition] = useState(null)

  useEffect(() => {
    window.addEventListener('mousemove', updatePosition)
    return function cleanup() {
      window.removeEventListener('mousemove', updatePosition, false)
    }
  }, [])

  const updatePosition = e => {
    setPosition({
      x: e.clientX,
      y: e.clientY,
			area: e.clientX > window.innerWidth * 0.8 ? 'right' : e.clientX < window.innerWidth * 0.2 ? 'left' : 'center'
    })
  }

  return position ? (
    <span
      className={`gallery-tooltip show`}
      style={{
        color: colour,
        top: position.y,
        left: position.x
      }}
    >
      {children(position.area)}
    </span>
  ) : null
}

export default Tooltip
